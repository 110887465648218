export const graphQLSchema = `
  input CheckoutInput {
    offerId: String
    paymentProvider: String
    tokenQty: Int
  }

  input EmailCalculationInputMonths {
    jan: Float
    feb: Float
    mar: Float
    apr: Float
    may: Float
    jun: Float
    jul: Float
    aug: Float
    sep: Float
    oct: Float
    nov: Float
    dec: Float
  }

  input EmailCalculationInputFuel {
    gasoline: String
    ethanol: String
    diesel: String
    gnv: String
  }

  input EmailCalculationInput {
    cnpj: String
    companyName: String
    year: String
    state: String
    electricityMethod: String
    electricity: EmailCalculationInputMonths
    gasType: [String]
    gasMethod: String
    gas: String
    employees: Int
    homeOfficeEmployeeCount: Int
    homeOfficeWorkdaysCount: Int
    homeOfficePeriodRange: Int
    homeOffice: Boolean
    transportMethod: String
    transport: EmailCalculationInputMonths
    transportRefunds: Float
    flightsNational: Int
    flightsInternational: Int
    solidWasteMonthly: Int
    solidWasteMethod: Int
    solidWasteUnit: Int
    organicWaste: Int
    solidWaste: Boolean
    ownVehicle: String
    fuel: EmailCalculationInputFuel
    movables: Int
    airConditioning: Boolean
    airConditioningSplit: Int
    airConditioningCentral: Int
    fireExtinguisher: Int
  }

  type Account {
    id: ID!
    userId: String
    totalOffset: Int
  }

  type Result {
    id: ID!
    carbonTon: Int
    status: String
  }

  type Offer {
    id: ID!
    result: [Result]
    nextToken: String
    carbonTon: Int
    tokenPrice: Float
    status: String
    redirectURL: String
  }

  type CalculationInput {
    id: ID!
    year: String
  }

  type CalculationItem {
    id: ID!
    carbonTon: Int
    calculationStatus: String
    calculationInput: CalculationInput
  }

  type Calculation {
    id: ID!
    nextToken: String
    list: [CalculationItem]!
  }

  type Checkout {
    id: ID!
    offerId: String
    paymentProvider: String
    redirectURL: String
  }

  type EmailCalculation {
    id: ID!
    sent: Boolean
  }

  type Query {
    account: Account
    offers: Offer
    offer(id: String!): Offer
    calculations(limit: Float, reversed: Boolean): [Calculation]
  }

  type Mutation {
    checkout(input: CheckoutInput!): Checkout
    sendCalculation(calculation: EmailCalculationInput): EmailCalculation
  }
`;

export default graphQLSchema;
