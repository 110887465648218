import firebaseApp, {
  googleProvider,
  microsoftProvider
} from '../utils/firebase';

export const FirebaseService = () => {
  const firebaseAuth = firebaseApp.auth();

  const signInWithGooglePopup = async () => {
    return firebaseAuth.signInWithPopup(googleProvider);
  };

  const signInWithMicrosoftPopup = async () => {
    return firebaseAuth.signInWithPopup(microsoftProvider);
  };

  const signInWithEmailAndPassword = async (email, password) => {
    return firebaseAuth.signInWithEmailAndPassword(email, password);
  };

  const signUpWithEmailAndPassword = async (email, password) => {
    return firebaseAuth.createUserWithEmailAndPassword(email, password);
  };

  const confirmPasswordReset = async (code, newPassword) => {
    return firebaseAuth.confirmPasswordReset(code, newPassword);
  };

  const currentUser = () => {
    return firebaseAuth.currentUser;
  };

  const getIdToken = async () => {
    if (!firebaseAuth.currentUser) {
      return null;
    }
    return firebaseAuth.currentUser.getIdToken(true);
  };

  const signOut = async () => {
    return firebaseAuth.signOut();
  };

  const onAuthStateChanged = (callback) => {
    firebaseAuth.onAuthStateChanged(callback);
  };

  const applyActionCode = (actionCode) => {
    return firebaseAuth.applyActionCode(actionCode);
  };

  return {
    auth: {
      applyActionCode,
      confirmPasswordReset,
      currentUser,
      getIdToken,
      onAuthStateChanged,
      signInWithEmailAndPassword,
      signInWithGooglePopup,
      signInWithMicrosoftPopup,
      signOut,
      signUpWithEmailAndPassword
    }
  };
};

export default FirebaseService;
