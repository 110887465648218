import CalculationModel from './calculation/model';
import CheckoutModel from './checkout/model';
import EmailCalculationModel from './emailCalculation/model';
import OfferModel from './offer/model';

export const Models = {
  ...CalculationModel,
  ...OfferModel,
  ...CheckoutModel,
  ...EmailCalculationModel
};

export default Models;
