const AUTH_KEY = 'token';

function setToken(token) {
  setItem(AUTH_KEY, token);
}

function removeToken() {
  removeItem(AUTH_KEY);
}

function getToken() {
  return getItem(AUTH_KEY);
}

function setItem(key, token) {
  localStorage.setItem(key, token);
}

function removeItem(key) {
  localStorage.removeItem(key);
}

function getItem(key) {
  return localStorage.getItem(key);
}

const storage = {
  setToken,
  removeToken,
  getToken,
  setItem,
  removeItem,
  getItem
};

export default storage;
