import { mirageGraphQLFieldResolver } from '@miragejs/graphql';
import faker from 'faker';

const checkout = (obj, args, context, info) => {
  args.input.redirectURL = faker.internet.url();

  return mirageGraphQLFieldResolver(obj, args, context, info);
};

export const CheckoutResolver = {
  Mutation: {
    checkout
  }
};

export default CheckoutResolver;
