import { createTheme } from '@mui/material';
// import { grey } from '@mui/material/colors';

const { typography, breakpoints, spacing } = createTheme();

const palette = {
  type: 'light',
  primary: {
    main: '#0F0F0F',
    contrastText: '#DEF72D'
  },
  secondary: {
    main: '#DEF72D',
    contrastText: '#0F0F0F'
  },
  background: {
    default: '#F2F2F2',
    paper: '#FCFCFC'
  },
  warning: {
    main: '#B95000',
    light: '#FFF4EC'
  },
  success: {
    main: '#287D3C',
    light: '#EDF9F0'
  },
  error: {
    main: '#C92A2A',
    light: '#FEEFEF'
  },
  info: {
    main: '#2E5AAC',
    light: '#EEF2FA'
  },
  text: {
    secondary: '#0F0F0F',
    primary: '#444444'
  },
  divider: '#D5D9DA',
  orange: {
    main: '#FF6420'
  }
};

export const makeDefaultTheme = () =>
  createTheme({
    palette,
    shape: {
      borderRadius: 0
    },
    typography: {
      fontFamily: ['Graphik', 'sans-serif'].join(','),
      h1: {
        fontSize: typography.pxToRem(40),
        fontWeight: 700,
        [breakpoints.down('sm')]: {
          fontSize: typography.pxToRem(32)
        }
      },
      h2: {
        fontSize: typography.pxToRem(32)
      },
      h3: {
        fontSize: typography.pxToRem(32),
        fontWeight: 'lighter',
        lineHeight: typography.pxToRem(48),
        [breakpoints.down('sm')]: {
          fontSize: typography.pxToRem(24),
          lineHeight: typography.pxToRem(48)
        }
      },
      h4: {
        fontSize: typography.pxToRem(24),
        [breakpoints.down('sm')]: {
          fontSize: typography.pxToRem(16)
        }
      },
      h5: {
        fontSize: typography.pxToRem(24)
      },
      h6: {
        fontSize: typography.pxToRem(24)
      },
      h7: {
        fontWeight: 600,
        fontSize: typography.pxToRem(24)
      },
      h8: {
        fontWeight: 600,
        fontSize: typography.pxToRem(32)
      },
      h9: {
        fontWeight: 400,
        fontSize: typography.pxToRem(24),
        lineHeight: typography.pxToRem(26.4)
      },
      h10: {
        fontWeight: 600,
        fontSize: typography.pxToRem(20)
      },
      link: {
        fontWeight: 600,
        fontSize: 14
      },
      body1: {
        fontWeight: 400,
        fontSize: typography.pxToRem(20),
        [breakpoints.down('sm')]: {
          fontSize: typography.pxToRem(14)
        }
      },
      body2: {
        fontSize: typography.pxToRem(20)
      },
      p1: {
        fontWeight: 300,
        fontSize: typography.pxToRem(24)
      },
      p2: {
        fontWeight: 400,
        fontSize: typography.pxToRem(16),
        lineHeight: typography.pxToRem(24)
      },
      p3: {
        fontSize: typography.pxToRem(14),
        fontWeight: 400
      },
      p4: {
        fontWeight: 500,
        fontSize: typography.pxToRem(20)
      },
      p5: {
        fontWeight: 600,
        fontSize: typography.pxToRem(24)
      },
      p6: {
        fontWeight: 400,
        fontSize: typography.pxToRem(24)
      },
      subtitle1: {
        fontWeight: 500,
        fontSize: typography.pxToRem(16)
      },
      subtitle2: {
        fontWeight: 400,
        fontSize: typography.pxToRem(16)
      },
      subtitle3: {
        fontWeight: 500,
        fontSize: typography.pxToRem(14)
      },
      subtitle4: {
        fontWeight: 400,
        fontSize: typography.pxToRem(14)
      },
      caption: {
        fontSize: typography.pxToRem(16),
        textTransform: 'uppercase'
      },
      caption1: {
        fontWeight: 400,
        fontSize: typography.pxToRem(12),
        lineHeight: typography.pxToRem(13.2)
      }
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            //temporary fix until design team make a better semantic pattern
            h1: 'h2',
            h2: 'h2',
            h3: 'h2',
            h4: 'h2',
            h5: 'h2',
            h6: 'h2',
            h7: 'h2',
            h8: 'h2',
            h9: 'h2',
            h10: 'h2',
            subtitle1: 'p',
            subtitle2: 'p',
            subtitle3: 'p',
            subtitle4: 'p',
            caption1: 'p'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: '40px'
          },
          sizeLarge: {
            padding: '15px 32px',
            fontWeight: 600,
            fontSize: typography.pxToRem(16),
            lineHeight: typography.pxToRem(18)
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none',
            color: palette.text.secondary
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            width: '100%'
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          input: {
            color: palette.text.secondary,
            '&:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 100px ${palette.background.paper} inset`,
              WebkitTextFillColor: palette.text.secondary
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            fontSize: 32,
            fontWeight: 600,
            minWidth: spacing(9),
            [breakpoints.down('sm')]: {
              fontSize: typography.pxToRem(24)
            }
          },
          select: {
            paddingTop: 6,
            paddingLeft: 12,
            paddingBottom: 0,
            paddingRight: '48px !important'
          },
          icon: {
            paddingTop: 10,
            marginRight: 12,
            [breakpoints.down('sm')]: {
              paddingTop: 4
            }
          }
        }
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: palette.primary.main,
            height: 5,
            padding: '16px 0'
          },
          thumb: {
            height: 30,
            width: 30,
            backgroundColor: palette.primary.main,
            // marginTop: -5,
            '&:focus, &:hover, &$active': {
              boxShadow: 'inherit'
            }
          },
          active: {},
          valueLabel: {
            left: 'calc(-50% + 4px)'
          },
          track: {
            height: 5,
            borderRadius: 4
          },
          rail: {
            height: 5,
            borderRadius: 4,
            backgroundColor: '#D5D9DA'
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            maxHeight: 320
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: '0'
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            zIndex: 2000
          }
        }
      }
    }
  });
