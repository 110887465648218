import { Factory } from 'miragejs';
import faker from 'faker';

const offerResult = Factory.extend({
  carbonTon() {
    return faker.datatype.number();
  },
  status() {
    return faker.random.arrayElement(['awaiting_payment', 'created']);
  }
});

const offer = Factory.extend({
  nextToken() {
    return faker.datatype.number();
  },
  carbonTon() {
    return faker.datatype.number();
  },
  tokenPrice() {
    return faker.finance.amount();
  },
  status() {
    return faker.random.arrayElement(['created']); // removed 'awaiting_payment' because will redirect when access offset/:id in the front
  },
  redirectURL() {
    return faker.internet.url();
  },
  afterCreate(offer, server) {
    offer.update({
      result: [server.create('offerResult')]
    });
  }
});

export const OfferFactory = {
  offerResult,
  offer
};

export default OfferFactory;
