/* eslint-disable prettier/prettier */
import { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { routes } from './utils/routes';
import { useAuth } from './contexts/Auth';
import { useHotjar } from './contexts/Hotjar';
import { clearRedir, getRedir, setRedir } from './utils/redirect';

// public
const Login = lazy(() => import('./pages/public/login'));
const EmailConfirmation = lazy(() =>
  import('./pages/public/emailConfirmation')
);
const NotFound = lazy(() => import('./pages/public/notFound'));
const Register = lazy(() => import('./pages/public/register'));
const ResetPassword = lazy(() => import('./pages/public/resetPassword'));
const NewPassword = lazy(() => import('./pages/public/newPassword'));
const Calculator = lazy(() => import('./pages/public/calculator'));
const DataLayer = lazy(() => import('./components/DataLayer'));

// private
const Dashboard = lazy(() => import('./pages/private/dashboard'));
const Offset = lazy(() => import('./pages/private/offset'));
const OffsetTicket = lazy(() => import('./pages/private/offsetTicket'));
const OffSetStampEmission = lazy(() =>
  import('./pages/private/offsetStampEmission')
);
const OffsetFinish = lazy(() => import('./pages/private/offsetFinish'));
const Documents = lazy(() => import('./pages/private/documents'));
const VerifyEmail = lazy(() => import('./pages/public/verifyEmail'));
const CalculationCheckout = lazy(() =>
  import('./pages/private/calculationCheckout')
);

const MyCertificate = lazy(() => import('./pages/private/myCertificate'));
const AuthAction = lazy(() => import('./pages/public/authAction'));

const SuspenseFallback = () => {
  return <CircularProgress color="secondary" />;
};

const RouteWatcher = ({ children }) => {
  const { stateChangeHotjar } = useHotjar();
  const location = useLocation();
  stateChangeHotjar(`${location.pathname}${location.search}${location.hash}`);
  return children;
};
function Router() {
  const { registering, currentUser } = useAuth();
  const emailVerified = currentUser?._delegate.emailVerified;

  return (
    <Suspense fallback={SuspenseFallback()}>
      <DataLayer />
      <Switch>
        <PrivateRoute
          path={routes.private.dashboard}
          component={Dashboard}
          exact
        />
        <PrivateRoute
          path={`${routes.private.documents}/:id/download/:document_type`}
          component={Documents}
          exact
        />
        <PrivateRoute path={routes.private.offset()} component={Offset} exact />
        <PrivateRoute
          path={routes.private.offsetTicket()}
          component={OffsetTicket}
          exact
        />
        <PrivateRoute
          path={routes.private.offsetStampEmission}
          component={OffSetStampEmission}
          exact
        />
        <PrivateRoute
          path={routes.private.offsetFinish}
          component={OffsetFinish}
          exact
        />
        <PrivateRoute
          path={routes.private.checkoutCalculation()}
          component={CalculationCheckout}
          exact
        />
        <PrivateRoute
          path={routes.private.myCertificate()}
          component={MyCertificate}
          exact
        />
        <Route
          path={routes.public.calculator}
          exact
          render={() => <Calculator />}
        />
        <PublicRoute path={routes.public.login} component={Login} exact />
        <PublicRoute path={routes.public.register} component={Register} exact />
        <PublicRoute
          path={routes.public.resetPassword}
          component={ResetPassword}
          exact
        />
        <PublicRoute
          path={routes.public.newPassword}
          component={NewPassword}
          exact
        />
        <PublicRoute
          path={`${routes.public.emailConfirmation}/:code?`}
          component={EmailConfirmation}
          exact
        />
        <PublicRoute
          path={routes.public.verifyEmail}
          component={VerifyEmail}
          exact
        />
        <Route path={routes.public.authAction} exact>
          <AuthAction />
        </Route>
        <PublicRoute path={routes.public.notFound} component={NotFound} exact />
        {/* <PublicRoute path="*" component={NotFound} /> */}
      </Switch>
    </Suspense>
  );

  function PrivateRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location: from }) => {
          let to = null;
          const redir = getRedir();
          if (redir && emailVerified) {
            clearRedir();
            to = { pathname: redir, state: from.state };
          } else if (!emailVerified) {
            to = { pathname: routes.public.login, state: from.state };
            setRedir(from.pathname);
          }
          return to ? (
            <Redirect to={to} />
          ) : (
            <RouteWatcher>
              <Component />
            </RouteWatcher>
          );
        }}
      />
    );
  }

  function PublicRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location: from }) => {
          return !emailVerified || registering ? (
            <RouteWatcher>
              <Component />
            </RouteWatcher>
          ) : (
            <Redirect
              to={{
                pathname: routes.private.dashboard,
                state: { from }
              }}
            />
          );
        }}
      />
    );
  }
}

export default Router;
