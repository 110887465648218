import { createContext, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CircularProgress } from '@mui/material';

import { useAuth } from '../Auth';
import { REGISTER } from '../../services/graphql/mutations';
import { GET_ACCOUNT } from '../../services/graphql/queries';

export const AccountContext = createContext();

export function AccountProvider({ children }) {
  const { logged, user, providerToken } = useAuth();
  const [isBCertFormEmpty, setBCertFormEmpty] = useState(true);
  const [account, setAccount] = useState(null);

  const [queryAccount, { loading, data }] = useLazyQuery(GET_ACCOUNT);
  const [registerUser] = useMutation(REGISTER);

  useEffect(() => {
    if (logged) {
      queryAccount({ variables: { userId: user.uid } });
    } else {
      setAccount(null);
    }
  }, [logged, queryAccount]);

  useEffect(() => {
    if (data?.account) {
      setAccount(data?.account);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const { account } = data;
      setBCertFormEmpty(
        !account?.businessCertificateForm?.cnpj ||
          !account?.businessCertificateForm?.companyName ||
          !account?.businessCertificateForm?.legalRepEmail ||
          !account?.businessCertificateForm?.legalRepName
      );
    }

    if (data?.account === null && user?.displayName !== null) {
      const variables = {
        userName: user.displayName,
        profilePic: user.photoURL
      };
      if (providerToken?.provider === 'ms') {
        variables.msAccessToken = providerToken.token;
        variables.profilePic = '';
      }
      registerUser({
        variables
      });
    }
  }, [data, user, registerUser, providerToken]);

  return (
    <AccountContext.Provider
      value={{
        account,
        queryAccount,
        isBCertFormEmpty
      }}
    >
      {loading && !account ? <CircularProgress /> : children}
    </AccountContext.Provider>
  );
}

export const useAccount = () => useContext(AccountContext);

export default AccountContext;
