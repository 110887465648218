import { Model, belongsTo } from 'miragejs';

const checkout = Model.extend({
  offer: belongsTo()
});

export const CheckoutModel = {
  checkout
};

export default CheckoutModel;
