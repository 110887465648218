import CalculationResolver from './calculation/resolver';
import CheckoutResolver from './checkout/resolver';
import EmailCalculationResolver from './emailCalculation/resolver';

export const resolvers = {
  resolvers: {
    Query: {
      ...CalculationResolver.Query
    },
    Mutation: {
      ...CheckoutResolver.Mutation,
      ...EmailCalculationResolver.Mutation
    }
  }
};

export default resolvers;
