/* eslint-disable import/no-anonymous-default-export */
import * as Sentry from '@sentry/react';

class CustomExceptionError extends Error {
  constructor(message) {
    super(message);
    this.name = 'CustomException';
  }
}

export const init = (config) => {
  const allowedEnvs = ['staging', 'production'].includes(
    process.env.REACT_APP_ENV
  );

  if (allowedEnvs) {
    const DEBUG = process.env.REACT_APP_DEBUG;
    Sentry.init({
      silent: DEBUG !== 'true',
      debug: DEBUG === 'true',
      ...config
    });
  }
};

export const captureException = ({
  error,
  additionalInfo = {},
  level = 'error',
  tag
} = {}) => {
  const isString = typeof error === 'string' || error instanceof String;
  const errorToSend = isString ? new CustomExceptionError(error) : error;

  Sentry.captureException(errorToSend, {
    contexts: {
      'ADDITIONAL CUSTOM EXCEPTION INFORMATION': additionalInfo
    },
    level,
    tags: {
      'custom.exception': tag
    }
  });
};

export const setUser = (id) => {
  Sentry.setUser({ id });
};

export const removeUser = () => {
  Sentry.configureScope((scope) => scope.setUser(null));
};

export default {
  init,
  captureException,
  setUser,
  removeUser
};
