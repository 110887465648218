import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { captureException } from '../utils/sentry';
import FirebaseService from '../services/firebase';
import { makeServer } from '../mirage/config';

export const createClient = ({ onUnauthenticated }) => {
  if (process.env.REACT_APP_MIRAGE_ENABLED === 'true') {
    makeServer({
      urlPrefix: process.env.REACT_APP_GATEWAY_SERVICE_URL,
      environment: process.env.REACT_APP_ENV,
      logging: process.env.REACT_APP_MIRAGE_LOGGING === 'true'
    });
  }

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_GATEWAY_SERVICE_URL}/graphql`
  });

  const authLink = setContext(async (_, { headers }) => {
    const { auth: firebaseAuth } = FirebaseService();

    const token = await firebaseAuth.getIdToken();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      captureException({
        error: '[GraphQL Network Error]',
        additionalInfo: networkError,
        tag: 'GraphQL Network Error'
      });
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        /* TODO: should returns 401 when the backend lost session */
        if (error.extensions.code === 'UNAUTHENTICATED') {
          onUnauthenticated();
        } else {
          captureException({
            error: '[GraphQL error]',
            additionalInfo: error,
            tag: 'GraphQL Error'
          });
          return error;
        }
      });
    }
  });

  return new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache()
  });
};
