import { hotjar } from 'react-hotjar';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

const hotjarID = Number(process.env.REACT_APP_HOTJAR_ID || '');
const hotjarVersion = Number(process.env.REACT_APP_HOTJAR_VERSION || '');

export const HotjarContext = createContext();

const hjLoaded = () => window && window.hj !== undefined;
const apiWrapper = (fn, hjready) => {
  if (!hjready) return;
  try {
    return fn();
  } catch (error) {
    // todo: send this to sentry
    console.error(`Hotjar error: ${error}`);
  }
};

export function HotjarProvider({ children }) {
  const [_hjready, _setHjready] = useState(
    useMemo(() => hjLoaded(), [hjLoaded])
  );

  useEffect(
    () =>
      apiWrapper(() => {
        if (!Number.isNaN(hotjarID) && !Number.isNaN(hotjarVersion)) {
          hotjar.initialize(hotjarID, hotjarVersion);
          _setHjready(true);
        }
      }, true),
    []
  );

  const identifyHotjar = useCallback(
    (userId, userInfo) =>
      apiWrapper(() => hotjar.identify(userId, userInfo), _hjready),
    []
  );

  const trackEventHotjar = useCallback(
    (evt) => apiWrapper(() => hotjar.event(evt), _hjready),
    []
  );

  const stateChangeHotjar = useCallback(
    (state) => apiWrapper(() => hotjar.event(state), _hjready),
    []
  );

  return (
    <HotjarContext.Provider
      value={{
        identifyHotjar,
        trackEventHotjar,
        stateChangeHotjar
      }}
    >
      {children}
    </HotjarContext.Provider>
  );
}

export const useHotjar = () => useContext(HotjarContext);

export default HotjarContext;
