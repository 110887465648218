import { createContext, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CURRENT_TERM } from '../../services/graphql/queries';
import { useAccount } from '../Account';

export const TermsContext = createContext();

export function TermsProvider({ children }) {
  const { account } = useAccount();
  const _pos = 'br';

  const [getCurrentTerm, { data }] = useLazyQuery(CURRENT_TERM, {
    variables: { pos: _pos }
  });
  const _currentTerm = data?.currentTerm;
  const _termUpdated = account?.termId === _currentTerm?.id;
  const [shouldRead, setShouldRead] = useState(false);

  useEffect(() => {
    if (account) getCurrentTerm();
  }, [getCurrentTerm, account]);

  useEffect(() => {
    if (account && _currentTerm && !_termUpdated) setShouldRead(true);
  }, [account, _currentTerm, _termUpdated]);

  //TODO: update pos to get from account document when implemented

  return (
    <TermsContext.Provider value={{ shouldRead, currentTerm: _currentTerm }}>
      {children}
    </TermsContext.Provider>
  );
}

export const useTerms = () => useContext(TermsContext);

export default TermsContext;
