import { Factory } from 'miragejs';
import faker from 'faker';

const emailCalculation = Factory.extend({
  sent() {
    return faker.datatype.boolean();
  }
});

export const EmailCalculationFactory = {
  emailCalculation
};

export default EmailCalculationFactory;
