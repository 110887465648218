import { Factory } from 'miragejs';
import faker from 'faker';

const calculationInput = Factory.extend({
  year() {
    return faker.random.arrayElement(['2011', '2012', '2013', '2014', '2015']);
  }
});

const calculationItem = Factory.extend({
  carbonTon() {
    return faker.datatype.number();
  },
  calculationStatus() {
    return faker.random.arrayElement(['awaiting_payment', 'created']);
  },
  afterCreate(calculationItem, server) {
    calculationItem.update({
      calculationInput: server.create('calculationInput')
    });
  }
});

const calculation = Factory.extend({
  nextToken() {
    return faker.datatype.number();
  },
  afterCreate(calculation, server) {
    calculation.update({
      list: [server.create('calculationItem')]
    });
  }
});

export const CalculationFactory = {
  calculationInput,
  calculationItem,
  calculation
};

export default CalculationFactory;
