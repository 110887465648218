const redirVar = 'redir';

export function getRedir() {
  return localStorage.getItem(redirVar);
}

export function setRedir(pathname) {
  if (!pathname) return;
  if (pathname === '/') return;
  localStorage.setItem(redirVar, pathname);
}

export function clearRedir() {
  localStorage.removeItem(redirVar);
}

export function getVerifyEmailRedirUrl() {
  const url = getRedir();
  return url ? new URL(document.URL).origin + url : '';
}
