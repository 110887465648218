import { Model, hasMany, belongsTo } from 'miragejs';

const calculationInput = Model;

const calculationItem = Model.extend({
  calculationInput: belongsTo()
});

const calculation = Model.extend({
  list: hasMany('calculationItem')
});

export const CalculationModel = {
  calculationInput,
  calculationItem,
  calculation
};

export default CalculationModel;
