import { gql } from '@apollo/client';

export const REGISTER = gql`
  mutation Account(
    $userName: String
    $profilePic: String
    $msAccessToken: String
  ) {
    createAccount(
      input: {
        userName: $userName
        profilePic: $profilePic
        msAccessToken: $msAccessToken
      }
    ) {
      id
    }
  }
`;

export const CHECKOUT_OFFER = gql`
  mutation CheckoutOffer($checkoutInput: CheckoutInput!) {
    checkout(input: $checkoutInput) {
      redirectURL
    }
  }
`;

export const CALCULATOR = gql`
  mutation CALCULATOR(
    $calculation: EmailCalculationInput!
    $input: BusinessCertificateFormInput!
  ) {
    calculation: sendCalculation(calculation: $calculation) {
      sent
    }

    form: addBusinessCertificateFormToAccount(input: $input) {
      userId
      businessCertificateForm {
        tradeName
      }
    }
  }
`;
export const CALCULATOR_NOT_LOGGED = gql`
  mutation CALCULATOR($calculation: EmailCalculationInput!) {
    calculation: sendCalculation(calculation: $calculation) {
      sent
    }
  }
`;

export const ACCEPT_TERM = gql`
  mutation Term {
    acceptTerm
  }
`;

export const UPDATE_LEGAL_REP = gql`
  mutation Account(
    $id: String!
    $legalRepFirstName: String!
    $legalRepLastName: String!
    $legalRepCPF: String!
  ) {
    addLegalRepFormToAccount(
      id: $id
      input: {
        legalRepFirstName: $legalRepFirstName
        legalRepLastName: $legalRepLastName
        legalRepCPF: $legalRepCPF
      }
    ) {
      id
      termId
      userId
      businessCertificateForm {
        cnpj
        legalName
        tradeName
        legalRepForm {
          legalRepFirstName
          legalRepLastName
          legalRepCPF
        }
      }
      businessProfile {
        name
        profilePic
      }
    }
  }
`;

export const SEND_BUSINESS_CERTIFICATE_FORM = gql`
  mutation BusinessCertificateForm($form: BusinessCertificateFormInput!) {
    addBusinessCertificateFormToAccount(input: $form) {
      id
    }
  }
`;

export const SET_PAYMENT_DONE = gql`
  mutation PaymentDone($offerId: String!) {
    PaymentDone(offerId: $offerId) {
      status
    }
  }
`;

export const SEND_VERIFY_EMAIL = gql`
  mutation VerifyEmail($redirectUrl: String) {
    sendVerifyEmail(redirectUrl: $redirectUrl)
  }
`;

export const CALCULATION_B2B = gql`
  mutation BusinessCalculatorBR(
    $calcId: String
    $extinguishers: Int!
    $centralAir: Int!
    $splitAir: Int!
    $multiSplitAir: Int!
    $refrigerators: Int!
    $cookingGasCost: Float!
    $energyCost: Float!
    $brazilianState: String!
    $year: Int!
    $homeOfficePercentage: Float!
    $numEmployees: Int!
    $fuelCost: Float!
    $ridesCost: Float!
    $nationalFlights: Int
    $internationalFlights: Int
    $username: String
    $branch: String
    $email: String!
    $energyCostKWH: Float
    $fuelCostLiters: Float
    $step: String
  ) {
    businessCalculatorBR(
      calcId: $calcId
      input: {
        step: $step
        ano: $year
        nome: $username
        estado: $brazilianState
        email: $email
        companyIndustry: $branch
        extintores: $extinguishers
        centrais: $centralAir
        splits: $splitAir
        multisplits: $multiSplitAir
        freezers: $refrigerators
        precoMedioGas: $cookingGasCost
        voosInternacionais: $internationalFlights
        voosNacionais: $nationalFlights
        transportePorTaxi: 0
        transportePorAplicativo: $ridesCost
        custoMedioEnergiaEletrica: $energyCost
        percentualDeHomeOffice: $homeOfficePercentage
        reembolsoCombustivel: $fuelCost
        totalTransporteFuncionarios: $numEmployees
        custoMedioEnergiaEletricakWh: $energyCostKWH
        reembolsoCombustivelLitros: $fuelCostLiters
      }
    ) {
      id
      footprint {
        carbonTon
        price
      }
      result {
        escopo1 {
          emissoesFugitivas {
            carbonTon
            price
          }
          gas {
            carbonTon
            price
          }
        }
        escopo2 {
          energiaEletrica {
            carbonTon
            price
          }
        }
        escopo3 {
          homeOffice {
            carbonTon
            price
          }
          reembolsoCombustivel {
            carbonTon
            price
          }
          residuoSolido {
            carbonTon
            price
          }
          transporteAereo {
            carbonTon
            price
          }
          transporteFuncionarios {
            carbonTon
            price
          }
          transporteAplicativoTaxi {
            carbonTon
            price
          }
        }
      }
    }
  }
`;

export const SEND_CALCULATION_EMAIL = gql`
  mutation SendCalculationEmail($email: String!, $calcId: String!) {
    sendCalculationEmail(email: $email, calcId: $calcId)
  }
`;

export const CHECKOUT_CALCULATION = gql`
  mutation CheckoutCalculation($calcId: String!) {
    checkoutCalculation(calcId: $calcId) {
      offerId
    }
  }
`;

export const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!, $code: String!, $password: String!) {
    resetPassword(email: $email, code: $code, password: $password)
  }
`;

export const CREATE_TICKET_HUBSPOT = gql`
  mutation CreateTicket($input: CreateTicketInput!) {
    createTicket(input: $input) {
      ticketId
      email
      phone
      offerId
    }
  }
`;
