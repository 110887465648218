import { ApolloProvider } from '@apollo/client';
import { createClient } from './client';
import { useAuth } from '../contexts/Auth';

export default function ServiceProvider({ children }) {
  const { signOut } = useAuth();

  const client = createClient({
    onUnauthenticated: signOut
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
