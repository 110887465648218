import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const supportedLocales = [
  {
    locale: 'pt-BR',
    currency: 'BRL'
  }
  // {
  //   locale: 'en',
  //   currency: 'USD'
  // }
];
export const fallbackLng = 'pt-BR';

const debug = !!process.env.DEBUG_I18N;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug,
    order: [
      'querystring',
      'cookie',
      'localStorage',
      'sessionStorage',
      'navigator',
      'htmlTag',
      'path',
      'subdomain'
    ],
    supportedLngs: supportedLocales.map((lng) => lng.locale),
    fallbackLng,
    ns: ['common'],
    load: 'currentOnly',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
