import { createServer } from 'miragejs';
import { createGraphQLHandler } from '@miragejs/graphql';
import models from './src/models';
import factories from './src/factories';
import resolvers from './src/resolvers';
import graphQLSchema from './src/graphQLSchema';

export function makeServer({ urlPrefix, environment = 'test', logging } = {}) {
  let server = createServer({
    environment,
    logging,
    models,
    factories,

    routes() {
      this.urlPrefix = urlPrefix;
      const graphQLHandler = createGraphQLHandler(
        graphQLSchema,
        this.schema,
        resolvers
      );

      this.post('/graphql', graphQLHandler);

      this.passthrough('https://apis.google.com/**');
      this.passthrough('https://moss-cloud-gcp.firebaseapp.com/**');
      this.passthrough('https://identitytoolkit.googleapis.com/**');
      this.passthrough('https://www.googleapis.com/**');
      this.passthrough('https://securetoken.googleapis.com/**');
    },

    seeds(server) {
      server.create('account', { id: 1, userId: 1, totalOffset: 12 });
      server.createList('offer', 2);
      server.createList('calculation', 2);
    }
  });

  return server;
}
