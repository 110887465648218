import { createContext, useState } from 'react';

import i18n from '../../i18n';
import utils from '../../utils';

const LANGUAGE_KEY = 'i18nextLng';

export const languageOptions = {
  en: 'English',
  'pt-BR': 'Portuguese'
};

export const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  const [userLanguage, setUserLanguage] = useState(
    utils.storage.getItem(LANGUAGE_KEY) || 'en'
  );

  const provider = {
    userLanguage,
    userLanguageChange: (selected) => {
      const newLanguage = languageOptions[selected] ? selected : 'en';

      setUserLanguage(newLanguage);
      i18n.changeLanguage(newLanguage);
      utils.storage.setItem(LANGUAGE_KEY, newLanguage);
    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
}
