import { mirageGraphQLFieldResolver } from '@miragejs/graphql';

const sendCalculation = (obj, args, context, info) => {
  args.calculation.sent = true;

  return mirageGraphQLFieldResolver(obj, args, context, info);
};

export const EmailCalculationResolver = {
  Mutation: {
    sendCalculation
  }
};

export default EmailCalculationResolver;
