import OfferFactory from './offer/factory';
import CalculationFactory from './calculation/factory';
import CheckoutFactory from './checkout/factory';
import EmailCalculationFactory from './emailCalculation/factory';

export const Factories = {
  ...OfferFactory,
  ...CalculationFactory,
  ...CheckoutFactory,
  ...EmailCalculationFactory
};

export default Factories;
