import { Factory } from 'miragejs';
import faker from 'faker';

const checkout = Factory.extend({
  paymentProvider() {
    return faker.random.arrayElement(['ebanx']);
  },
  tokenQty() {
    return faker.datatype.number();
  },
  redirectURL() {
    return faker.internet.url();
  }
});

export const CheckoutFactory = {
  checkout
};

export default CheckoutFactory;
