import { createContext, useContext, useState, useEffect } from 'react';

import {
  setUser as setSentryUser,
  removeUser as removeSentryUser
} from '../../utils/sentry';
import FirebaseService from '../../services/firebase';
import { routes } from '../../utils/routes';
import { useHotjar } from '../Hotjar';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [_destination] = useState(window.location.pathname);
  const [_user, _setUser] = useState(null);
  const [providerToken, setProviderToken] = useState(null);
  const [_isStarted, _setIsStarted] = useState(false);
  const [_logged, _setLogged] = useState(false);
  const [authError, _setAuthError] = useState(null);
  const { identifyHotjar } = useHotjar();

  const { auth: FirebaseAuth } = FirebaseService();

  const currentUser = FirebaseAuth.currentUser();
  const registering = [
    routes.public.register,
    routes.public.verifyEmail
  ].includes(_destination);

  const setUser = (user) => {
    if (user?.uid) {
      _setUser(user);
      setSentryUser(user.uid);
      identifyHotjar(user.uid);
    } else {
      _setUser(null);
      identifyHotjar(null);
      removeSentryUser();
    }
  };

  const signOut = async () => {
    return FirebaseAuth.signOut();
  };

  const signInWithGooglePopup = async (callback) => {
    return FirebaseAuth.signInWithGooglePopup()
      .then(async (result) => {
        await callback(result);
      })
      .catch((error) => {
        console.log('Error when tries to Sign In with Google Popup', error);
      });
  };

  const signInWithMicrosoftPopup = async (callback) => {
    return FirebaseAuth.signInWithMicrosoftPopup()
      .then(async (result) => {
        const credential = result.credential;
        const accessToken = credential.accessToken;
        setProviderToken({ token: accessToken, provider: 'ms' });
        await callback({
          ...result,
          additionalUserInfo: result.additionalUserInfo,
          msAccessToken: accessToken
        });
      })
      .catch((error) => {
        console.log('Error when tries to Sign In with Microsoft Popup', error);
      });
  };

  const signInWithGoogleEmailAndPassword = async (email, password) => {
    return FirebaseAuth.signInWithEmailAndPassword(email, password).catch(
      (error) => {
        console.log(
          'Error when tries to Sign In with Google Email and Password',
          error
        );
      }
    );
  };

  const signInWithEmailAndPassword = async (email, password) => {
    _setAuthError(null);
    return FirebaseAuth.signInWithEmailAndPassword(email, password)
      .then((res) => {
        // if success
        if (res.user) {
          _setLogged(true);
        }
      })
      .catch((error) => {
        _setAuthError(error);
        console.log(
          'Error when tries to Sign In with Email and Password',
          error
        );
      });
  };
  const signUpWithEmailAndPassword = async (email, password) => {
    return FirebaseAuth.signUpWithEmailAndPassword(email, password);
  };

  useEffect(() => {
    FirebaseAuth.onAuthStateChanged((authUser) => {
      if (Boolean(authUser) && !_logged) {
        setUser(authUser);
        _setLogged(true);
      }

      if (!authUser) {
        setUser(authUser);
        _setLogged(false);
      }

      if (!_isStarted) _setIsStarted(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        logged: _logged,
        isStarted: _isStarted,
        user: _user,
        providerToken,
        setUser,
        destination: _destination,
        signOut,
        signInWithGooglePopup,
        signInWithGoogleEmailAndPassword,
        signInWithMicrosoftPopup,
        signInWithEmailAndPassword,
        signUpWithEmailAndPassword,
        registering,
        currentUser,
        authError,
        confirmPasswordReset: FirebaseAuth.confirmPasswordReset,
        applyActionCode: FirebaseAuth.applyActionCode
      }}
    >
      {_isStarted && children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
