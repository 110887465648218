import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { Integrations } from '@sentry/tracing';
import { init as SentryInit } from './utils/sentry';
import packageInfo from '../package.json';
import { worker } from './mocks/browser';
import { BrowserRouter } from 'react-router-dom';

if (process.env.REACT_APP_MOCK_ENABLED === 'true') {
  worker.start();
}

const dsn = process.env.REACT_APP_SENTRY_DSN;
const environment = process.env.REACT_APP_ENV;
const APP_VERSION = packageInfo.version;

SentryInit({
  dsn,
  environment,
  release: APP_VERSION,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: environment === 'staging' ? 1.0 : 0.2
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// force rebuild 8

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
