import { mirageGraphQLFieldResolver } from '@miragejs/graphql';

const calculations = (obj, args, context, info) => {
  delete args.limit;
  delete args.reversed;

  const records = mirageGraphQLFieldResolver(obj, args, context, info);

  return records;
};

export const CalculationResolver = {
  Query: {
    calculations
  }
};

export default CalculationResolver;
