export const routes = {
  private: {
    dashboard: '/',
    contact: '/contact',
    offset: (offerId = ':offerId') => `/offset/${offerId}`,
    offsetTicket: (offerId = ':offerId') => `/offset/${offerId}/ticket`,
    payment: '/payment',
    offsetStampEmission: '/stamp-emission',
    offsetFinish: '/finish',
    documents: '/documents',
    checkoutCalculation: (calcId = ':calcId') =>
      `/calculation/${calcId}/checkout`,
    myCertificate: (calcId = ':calcId') => `/calculation/${calcId}/documents`
  },
  public: {
    login: '/login',
    register: '/register',
    resetPassword: '/reset-password',
    newPassword: '/new-password',
    calculator: '/calculator',
    credentials: '/register/credentials',
    verifyEmail: '/register/verify-email',
    emailConfirmation: '/email-confirmation',
    authAction: '/auth/action',
    notFound: '/not-found'
  }
};
