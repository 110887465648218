import { useLazyQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useRouterQuery } from '../../hooks/useQuery';
import { QUERY_GET_FULL_CALC_BY_ID } from '../../services/graphql/queries';

export const CalculatorContext = createContext({});

export function CalculatorProvider({ children }) {
  const routerQuery = useRouterQuery();
  const [routerCalcId] = useState(routerQuery.get('calcId') || undefined);
  const [calcId, setCalcId] = useState(routerCalcId);
  const [step, setStep] = useState(1);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const [totalTon, setTotalTon] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const advanceStep = () => setStep(step + 1);
  const returnStep = () => setStep(step - 1);

  // Step One
  const [username, setUsername] = useState('');
  const [year, setYear] = useState(2023);
  const [branch, setBranch] = useState('');
  const [brazilianState, setBrazilianState] = useState('');
  const [email, setEmail] = useState('');

  // Step Two
  const [numEmployees, setNumEmployees] = useState(1);
  const [homeOfficePercentage, setHomeOfficePercentage] = useState(0);

  // Step Three
  const [fuelCost, setFuelCost] = useState(0);
  const [fuelCostLiters, setFuelCostLiters] = useState(0);
  const [ridesCost, setRidesCost] = useState(0);
  const [fuelToggle, setFuelToggle] = useState(false);

  // Step Four
  const [nationalFlights, setNationalFlights] = useState(0);
  const [internationalFlights, setInternationalFlights] = useState(0);

  // Step Five
  const [energyCost, setEnergyCost] = useState(0);
  const [energyCostKWH, setEnergyCostKWH] = useState(0);
  const [energyCostToggle, setEnergyCostToggle] = useState(false);

  // Step Six
  const [refrigerators, setRefrigerators] = useState(0);
  const [extinguishers, setExtinguishers] = useState(0);

  // Step Seven
  const [splitAir, setSplitAir] = useState(0);
  const [multiSplitAir, setMultiSplitAir] = useState(0);
  const [centralAir, setCentralAir] = useState(0);

  // Step Eight
  const [cookingGasCost, setCookingGasCost] = useState(0);

  const [getFullCalcById, { loading: calcLoading }] = useLazyQuery(
    QUERY_GET_FULL_CALC_BY_ID,
    {
      onCompleted: ({ calculationById: calcData }) => {
        const input = calcData?.calculationInput;
        if (!input || calcData.calculationStatus !== 'created') {
          setCalcId(undefined);
          return;
        }
        setEmail(input.email);
        setYear(input.ano);
        setUsername(input.nome);
        setBrazilianState(input.estado);
        setBranch(input.companyIndustry);
        setExtinguishers(input.extintores);
        setCentralAir(input.centrais);
        setSplitAir(input.splits);
        setMultiSplitAir(input.multisplits ?? 0);
        setRefrigerators(input.freezers);
        setCookingGasCost(input.precoMedioGas);
        setInternationalFlights(input.voosInternacionais);
        setNationalFlights(input.voosNacionais);
        setRidesCost(input.transportePorAplicativo);
        setHomeOfficePercentage(input.percentualDeHomeOffice);
        setNumEmployees(input.totalTransporteFuncionarios);

        if (input.reembolsoCombustivelLitros) {
          setFuelToggle(true);
          setFuelCostLiters(input.reembolsoCombustivelLitros);
        } else {
          setFuelCost(input.reembolsoCombustivel);
        }

        if (input.custoMedioEnergiaEletricakWh) {
          setEnergyCostToggle(true);
          setEnergyCostKWH(input.custoMedioEnergiaEletricakWh);
        } else {
          setEnergyCost(input.custoMedioEnergiaEletrica);
        }

        try {
          let foundStep = input.step;
          foundStep = parseInt({ final: 9 }[foundStep] ?? foundStep, 10);
          if (foundStep > 0) setStep(foundStep);
        } catch (error) {}
      },
      onError: () => {
        setCalcId(undefined);
      }
    }
  );

  useEffect(async () => {
    if (routerCalcId) {
      getFullCalcById({
        variables: {
          calcId: routerCalcId
        }
      });
    }
  }, [routerCalcId]);

  const resetState = useCallback(() => {
    setCalcId(undefined);
    setStep(1);
    setUsername('');
    setYear(2023);
    setBranch('');
    setBrazilianState('');
    setEmail('');
    setNumEmployees(1);
    setHomeOfficePercentage(0);
    setFuelCost(0);
    setRidesCost(0);
    setNationalFlights(0);
    setInternationalFlights(0);
    setEnergyCost(0);
    setRefrigerators(0);
    setExtinguishers(0);
    setSplitAir(0);
    setMultiSplitAir(0);
    setCentralAir(0);
    setCookingGasCost(0);
  }, []);

  return (
    <CalculatorContext.Provider
      value={{
        calcId,
        setCalcId,
        drawerOpen,
        setDrawerOpen,
        step,
        advanceStep,
        returnStep,
        username,
        setUsername,
        year,
        setYear,
        branch,
        setBranch,
        brazilianState,
        setBrazilianState,
        email,
        setEmail,
        numEmployees,
        setNumEmployees,
        homeOfficePercentage,
        setHomeOfficePercentage,
        fuelCost,
        setFuelCost,
        ridesCost,
        setRidesCost,
        nationalFlights,
        setNationalFlights,
        internationalFlights,
        setInternationalFlights,
        energyCost,
        setEnergyCost,
        refrigerators,
        setRefrigerators,
        splitAir,
        setSplitAir,
        multiSplitAir,
        setMultiSplitAir,
        centralAir,
        setCentralAir,
        extinguishers,
        setExtinguishers,
        cookingGasCost,
        setCookingGasCost,
        totalTon,
        setTotalTon,
        totalPrice,
        setTotalPrice,
        showContact,
        setShowContact,
        fuelCostLiters,
        setFuelCostLiters,
        energyCostKWH,
        setEnergyCostKWH,
        fuelToggle,
        setFuelToggle,
        energyCostToggle,
        setEnergyCostToggle,
        resetState
      }}
    >
      {calcLoading ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </CalculatorContext.Provider>
  );
}
