import { graphql } from 'msw';

const gatewayAPI = graphql.link(
  `${process.env.REACT_APP_GATEWAY_SERVICE_URL}/graphql`
);

export const handlers = [
  gatewayAPI.query('Account', (req, res, ctx) => {
    return res(
      ctx.data({
        account: {
          id: '11',
          totalOffset: 10,
          userId: '22'
        }
      })
    );
  }),
  gatewayAPI.query('ListOffers', (req, res, ctx) => {
    return res(
      ctx.data({
        offers: {
          result: [
            {
              id: 123123,
              carbonTon: 13,
              status: 'pending'
            }
          ],
          nextToken: 2
        }
      })
    );
  }),
  gatewayAPI.mutation('BusinessCertificateForm', (req, res, ctx) => {
    const { form } = req.variables;

    return res(
      ctx.data({
        addBusinessCertificateFormToAccount: {
          id: '123',
          businessCertificateForm: {
            cnpj: form.cnpj,
            legalName: form.legalName,
            tradeName: form.tradeName,
            legalRepForm: {
              legalRepFirstName: form.legalRepForm.legalRepFirstName,
              legalRepLastName: form.legalRepForm.legalRepLastName,
              legalRepCPF: form.legalRepForm.legalRepCPF,
              legalRepEmail: form.legalRepForm.legalRepEmail,
              legalRepPhone: form.legalRepForm.legalRepPhone
            }
          }
        }
      })
    );
  })
];
