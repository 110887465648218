import { gql } from '@apollo/client';

export const GET_ACCOUNT = gql`
  query Account($userId: String) {
    account(userId: $userId) {
      id
      userId
      totalOffset
      termId
      businessCertificateForm {
        cnpj
        companyName
        legalRepName
        legalRepEmail
      }
    }
  }
`;

export const GET_OFFERS = gql`
  query ListOffers($userId: String) {
    offers(userId: $userId) {
      result {
        userId
        id
        carbonTon
        status
        referenceId
        referenceData {
          year
        }
      }
      nextToken
    }
  }
`;

export const GET_OFFER = gql`
  query GetOfferById($id: String!) {
    offer(id: $id) {
      carbonTon
      tokenPrice
      status
      redirectURL
      referenceData {
        year
      }
    }
  }
`;

export const GET_OFFER_COMPLETE = gql`
  query GetOffersComplete($userId: String) {
    offers(userId: $userId, status: "completed") {
      result {
        referenceId
        status
        referenceData {
          year
        }
      }
    }
  }
`;

export const QUERY_CALCULATIONS = gql`
  query QueryCalculations($limit: Float, $reversed: Boolean, $userId: String) {
    calculations(limit: $limit, reversed: $reversed, userId: $userId) {
      nextToken
      list {
        userId
        carbonTon
        calculationStatus
        calculationInput {
          year
        }
      }
    }
  }
`;

export const QUERY_GET_FULL_CALC_BY_ID = gql`
  query GetFullCalcById($calcId: String!) {
    calculationById(calcId: $calcId) {
      id
      step
      email
      calculationStatus
      calculationInput {
        nome
        ano
        estado
        tipoDeGas
        precoMedioGas
        GNV
        etanolHidratado
        diesel
        gasolina
        extintores
        centrais
        splits
        multisplits
        freezers
        custoMedioEnergiaEletrica
        percentualDeHomeOffice
        totalTransporteFuncionarios
        transportePorTaxi
        transportePorAplicativo
        voosInternacionais
        voosNacionais
        reembolsoCombustivel
        companyIndustry
        email
        custoMedioEnergiaEletricakWh
        reembolsoCombustivelLitros
        step
      }
      footprint {
        carbonTon
        price
      }
      result {
        escopo1 {
          emissoesFugitivas {
            carbonTon
            price
          }
          gas {
            carbonTon
            price
          }
        }
        escopo2 {
          energiaEletrica {
            carbonTon
            price
          }
        }
        escopo3 {
          homeOffice {
            carbonTon
            price
          }
          reembolsoCombustivel {
            carbonTon
            price
          }
          residuoSolido {
            carbonTon
            price
          }
          transporteAereo {
            carbonTon
            price
          }
          transporteFuncionarios {
            carbonTon
            price
          }
          transporteAplicativoTaxi {
            carbonTon
            price
          }
        }
      }
    }
  }
`;

export const QUERY_GET_CALC_BY_ID = gql`
  query GetCalcById($calcId: String!) {
    calculationById(calcId: $calcId) {
      id
      hash
      calculationInput {
        ano
      }
    }
  }
`;

export const CURRENT_TERM = gql`
  query Term($pos: String!) {
    currentTerm(input: { pos: $pos }) {
      id
      version
    }
  }
`;

export const GET_TICKET_LIST = gql`
  query GetTicketList($offerId: String) {
    getTicketList(offerId: $offerId) {
      result {
        ticketId
        status
        createdAt
      }
    }
  }
`;
