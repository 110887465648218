import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import ApolloProvider from './services/provider';
import * as Sentry from '@sentry/react';
import Router from './router';
import { LanguageProvider } from './contexts/Language';
import { AuthProvider } from './contexts/Auth';
import { AccountProvider } from './contexts/Account';
import { TermsProvider } from './contexts/Terms';
import { CalculatorProvider } from './contexts/Calculator';
import { HotjarProvider } from './contexts/Hotjar';
import { makeDefaultTheme } from './styles/themes/default';
import { StrictMode } from 'react';

function FallbackComponent() {
  return <div>An error has occured</div>;
}

export default function App() {
  const theme = makeDefaultTheme();

  return (
    <StrictMode>
      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
        <HotjarProvider>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <ApolloProvider>
                <AccountProvider>
                  <LanguageProvider>
                    <CssBaseline />
                    <TermsProvider>
                      <CalculatorProvider>
                        <BrowserRouter>
                          <Router />
                        </BrowserRouter>
                      </CalculatorProvider>
                    </TermsProvider>
                  </LanguageProvider>
                </AccountProvider>
              </ApolloProvider>
            </AuthProvider>
          </ThemeProvider>
        </HotjarProvider>
      </Sentry.ErrorBoundary>
    </StrictMode>
  );
}
