import { Model, hasMany } from 'miragejs';

const offerResult = Model;

const offer = Model.extend({
  result: hasMany('offerResult')
});

export const OfferModel = {
  offerResult,
  offer
};

export default OfferModel;
